import React from 'react';

export const Stats = (props) => {

    let statsSection = props.data.map((item) => (
        <div className="col-12 md:col-6 lg:col-3"
             key={item.id}>
            <div className="surface-0 shadow-2 p-3 border-1 border-50 border-round">
                <div className="flex justify-content-between mb-3">
                    <div>
                        <span className="block text-500 font-medium mb-3">{item.name}</span>
                        <div className="text-900 font-medium text-xl">{item.number}</div>
                    </div>
                    <div className="flex align-items-center justify-content-center bg-blue-100 border-round"
                         style={{width: '2.5rem', height: '2.5rem'}}>
                        <i className={[item.icon, "text-blue-500 text-xl"].join(' ')}></i>
                    </div>
                </div>
                <span className="text-green-500 font-medium">{item.increaseBy}</span>
                <span className="text-500">{item.since}</span>
            </div>
        </div>)
    );

    return (
        <section id="stats" className="grid container">
            {statsSection}
        </section>
    );
};