import React from 'react';
// import {Button} from 'primereact/button';
import HeroLogo from '../../assets/images/hero-road.jpg';

export const Hero = (props) => {
    return (

        <section id="hero" className="w-12 text-800">


            <div className="relative p-6 overflow-hidden"><img src={HeroLogo} alt="hero-2"
                                                               className="absolute top-0 left-0 w-auto h-full block md:w-full"></img>
                <div className="text-center my-6 relative">
                    <div className="text-6xl text-white font-bold mb-1">The Platform For</div>
                    <div className="text-6xl text-primary font-bold mb-4">Today's Generation</div>
                    <p className="mt-0 mb-4 line-height-3 text-center mx-auto text-white"
                       >Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
                        tempor incididunt ut labore et dolore magna aliqua.</p>
                    <button aria-label="Learn More" type="button" className="p-button p-component"><span
                        className="p-button-label p-c">Learn More</span><span role="presentation"
                                                                              className="p-ink"></span></button>
                    <p className="text-sm mt-4 mb-4 line-height-3 text-white">Available for MacOS, Web and Google
                        accounts only</p>
                    <div className="flex justify-content-center align-items-center"><a href="https://www.apple.com"
                                                                                       className="text-white mr-3"><i
                        className="pi pi-apple text-2xl"></i></a><a href="https://play.google.com"
                                                                    className="text-white mr-3"><i
                        className="pi pi-android text-2xl"></i></a><a href="https://www.facebook.com"
                                                                      className="text-white"><i
                        className="pi pi-facebook text-2xl"></i></a></div>
                </div>
            </div>
            {/*<div className="col-12 md:col-6 p-6 text-center md:text-left flex align-items-center ">*/}
            {/*    <section>*/}
            {/*        <span className="block text-6xl font-bold mb-1">Create the screens your</span>*/}
            {/*        <div className="text-6xl text-primary font-bold mb-3">your visitors deserve to see</div>*/}
            {/*        <p className="mt-0 mb-4 text-700 line-height-3">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>*/}

            {/*        <Button label="Learn More" type="button" className="mr-3 p-button-raised" />*/}
            {/*        <Button label="Live Demo" type="button" className="p-button-outlined" />*/}
            {/*    </section>*/}
            {/*</div>*/}
            {/*<div className="col-12 md:col-6 overflow-hidden">*/}
            {/*    <img src={HeroLogo} alt="hero-1" className="md:ml-auto block md:h-screen" style={{ clipPath: 'polygon(8% 0, 100% 0%, 100% 100%, 0 100%)' }} />*/}
            {/*</div>*/}
        </section>

    );
};