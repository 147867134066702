import React from 'react';
import car1 from '../../assets/images/car/1.jpg'
import car2 from '../../assets/images/car/2.jpg'
import car3 from '../../assets/images/car/3.jpg'
import car4 from '../../assets/images/car/4.jpg'

export const About = (props) => {

    return (
        <section id="about" className=" surface-ground">

                <div className="row container flex flex-column">
                    <div className="col-xs-12 col-md-6 flex justify-content-center">
                        {" "}
                        <img src={car1} className="img-responsive w-2 m-1" alt=""/>{" "}
                        <img src={car2} className="img-responsive w-2 m-1" alt=""/>{" "}
                        <img src={car3} className="img-responsive w-2 m-1" alt=""/>{" "}
                        <img src={car4} className="img-responsive w-2 m-1" alt=""/>{" "}
                    </div>
                    <div className="col-xs-12 col-md-6">
                        <div className="about-text">
                            <h2>About Us</h2>
                            <p>{props.data ? props.data.paragraph : "loading..."}</p>
                            <h3>Why Choose Us?</h3>
                            <div className="list-style">
                                <div className="col-lg-6 col-sm-6 col-xs-12">
                                    <ul>
                                        {props.data
                                            ? props.data.Why.map((d, i) => (
                                                <li key={`${d}-${i}`}>{d}</li>
                                            ))
                                            : "loading"}
                                    </ul>
                                </div>
                                <div className="col-lg-6 col-sm-6 col-xs-12">
                                    <ul>
                                        {props.data
                                            ? props.data.Why2.map((d, i) => (
                                                <li key={`${d}-${i}`}> {d}</li>
                                            ))
                                            : "loading"}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
        </section>
    );
};
