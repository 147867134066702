
export class SchoolService {

    getSchoolsStudentChoise() {
        return fetch('/data/data.json')
            .then(res => res.json())
            .then(data => data.StudentChoise);
    }

    getSchoolsAll() {
        return fetch('~data/data.json').then(res => res.json()).then(d => d.data);
    }

    getSchoolsWithOrdersSmall() {
        return fetch('~data/data.json').then(res => res.json()).then(d => d.data);
    }
}
