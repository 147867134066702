import React, { Component } from 'react';
import { Carousel } from 'primereact/carousel';
import { Button } from 'primereact/button';
import { SchoolService } from '../../service/SchoolService';

export class StudentChoise extends Component {

    constructor(props) {
        super(props);

        this.state = {
            schools: []
        };

        this.responsiveOptions = [
            {
                breakpoint: '1024px',
                numVisible: 3,
                numScroll: 3
            },
            {
                breakpoint: '600px',
                numVisible: 2,
                numScroll: 2
            },
            {
                breakpoint: '480px',
                numVisible: 1,
                numScroll: 1
            }
        ];

        this.schoolService = new SchoolService();
        this.schoolTemplate = this.schoolTemplate.bind(this);
    }

    componentDidMount() {
        this.schoolService.getSchoolsStudentChoise().then(data => this.setState({ schools: data.slice(0,9) }));
    }

    schoolTemplate(school) {
        return (
            <div className="school-item">
                <div className="school-item-content">
                    <div className="mb-3">
                        <img src={`/images/car/${school.image}`} onError={(e) => e.target.src='https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png'} alt={school.name} className="school-image" />
                    </div>
                    <div>
                        <h4 className="mb-1">{school.name}</h4>
                        <h6 className="mt-0 mb-3">${school.price}</h6>
                        <span className={`school-badge status-${school.inventoryStatus.toLowerCase()}`}>{school.inventoryStatus}</span>
                        <div className="car-buttons mt-5">
                            <Button icon="pi pi-search" className="p-button p-button-rounded mr-2" />
                            <Button icon="pi pi-star-fill" className="p-button-success p-button-rounded mr-2" />
                            <Button icon="pi pi-cog" className="p-button-help p-button-rounded" />
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    render() {
        return (
            <div id="student-choise" className="carousel">
                <div className="card">
                    <Carousel value={this.state.schools} numVisible={3} numScroll={3} responsiveOptions={this.responsiveOptions}
                              itemTemplate={this.schoolTemplate} header={<h5>Basic</h5>} />
                </div>
            </div>
        );
    }
}
