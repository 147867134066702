import React from 'react';
import moto11 from '../../assets/images/moto/11.png';
import moto2 from '../../assets/images/moto/2.jpg';
import moto3 from '../../assets/images/moto/3.jpg';
import moto4 from '../../assets/images/moto/4.jpg';


export const AdvantagesForStudents = (props) => {
    return (
        <section id="advantages-for-students" className="surface-section px-4 py-8 md:px-6 lg:px-8">
            <div className="flex flex-column md:flex-row md:align-items-center md:justify-content-between mb-5">
                <span className="text-900 text-3xl font-medium">Advantages for Students</span>
                <ul className="list-none p-0 m-0 flex mt-3 md:mt-0">
                    <li><p tabIndex="0"
                           className="cursor-pointer text-900 font-bold text-xl block hover:surface-200 transition-colors transition-duration-300 border-round px-3 py-2">NEW</p>
                    </li>
                    <li><p tabIndex="0"
                           className="cursor-pointer text-600 font-medium text-xl block hover:surface-200 transition-colors transition-duration-300 border-round px-3 py-2">SALE</p>
                    </li>
                    <li><p tabIndex="0"
                           className="cursor-pointer text-600 font-medium text-xl block hover:surface-200 transition-colors transition-duration-300 border-round px-3 py-2">POPULAR</p>
                    </li>
                </ul>
            </div>
            <div className="grid -mt-3 -ml-3 -mr-3">
                <div className="flex col-12 md:col-6 lg:col-3 mb-5 md:mb-0">
                    <div className="p-2 flex flex-column justify-content-between">
                        <div className="relative mb-3"><img
                            src={moto11} alt=""
                            className="w-full"/></div>
                        <div className="surface-section pt-3 z-1 relative transition-all transition-duration-300">
                            <div className="text-center text-700 font-medium text-xl mb-3">BRAND</div>
                            <div className="mb-3 text-center"><i
                                className="pi pi-star-fill text-yellow-500 mr-1"></i><i
                                className="pi pi-star-fill text-yellow-500 mr-1"></i><i
                                className="pi pi-star-fill text-yellow-500 mr-1"></i><i
                                className="pi pi-star-fill text-yellow-500 mr-1"></i><i
                                className="pi pi-star-fill text-300"></i></div>
                            <div className="text-center text-900 text-xl mb-3">Product Name</div>
                            <div className="text-center text-900 font-medium text-xl mb-3">$50.00</div>
                        </div>
                    </div>
                </div>
                <div className="flex col-12 md:col-6 lg:col-3 mb-5 md:mb-0">
                    <div className="p-2 flex flex-column justify-content-between">
                        <div className="relative mb-3"><img
                            src={moto2} alt=""
                            className="w-full"/></div>
                        <div className="surface-section pt-3 z-1 relative transition-all transition-duration-300">
                            <div className="text-center text-700 font-medium text-xl mb-3">BRAND</div>
                            <div className="mb-3 text-center"><i
                                className="pi pi-star-fill text-yellow-500 mr-1"></i><i
                                className="pi pi-star-fill text-yellow-500 mr-1"></i><i
                                className="pi pi-star-fill text-yellow-500 mr-1"></i><i
                                className="pi pi-star-fill text-yellow-500 mr-1"></i><i
                                className="pi pi-star-fill text-300"></i></div>
                            <div className="text-center text-900 text-xl mb-3">Product Name</div>
                            <div className="text-center text-900 font-medium text-xl mb-3">$50.00</div>
                        </div>
                    </div>
                </div>
                <div className="flex col-12 md:col-6 lg:col-3 mb-5 md:mb-0">
                    <div className="p-2 flex flex-column justify-content-between">
                        <div className="relative mb-3"><img
                            src={moto3} alt=""
                            className="w-full"/></div>
                        <div className="surface-section pt-3 z-1 relative transition-all transition-duration-300">
                            <div className="text-center text-700 font-medium text-xl mb-3">BRAND</div>
                            <div className="mb-3 text-center"><i
                                className="pi pi-star-fill text-yellow-500 mr-1"></i><i
                                className="pi pi-star-fill text-yellow-500 mr-1"></i><i
                                className="pi pi-star-fill text-yellow-500 mr-1"></i><i
                                className="pi pi-star-fill text-yellow-500 mr-1"></i><i
                                className="pi pi-star-fill text-300"></i></div>
                            <div className="text-center text-900 text-xl mb-3">Product Name</div>
                            <div className="text-center text-900 font-medium text-xl mb-3">$50.00</div>
                        </div>
                    </div>
                </div>
                <div className="flex col-12 md:col-6 lg:col-3 mb-5 md:mb-0">
                    <div className="p-2 flex flex-column justify-content-between">
                        <div className="relative mb-3"><img
                            src={moto4} alt=""
                            className="w-full"/></div>
                        <div className="surface-section pt-3 z-1 relative transition-all transition-duration-300">
                            <div className="text-center text-700 font-medium text-xl mb-3">BRAND</div>
                            <div className="mb-3 text-center"><i
                                className="pi pi-star-fill text-yellow-500 mr-1"></i><i
                                className="pi pi-star-fill text-yellow-500 mr-1"></i><i
                                className="pi pi-star-fill text-yellow-500 mr-1"></i><i
                                className="pi pi-star-fill text-yellow-500 mr-1"></i><i
                                className="pi pi-star-fill text-300"></i></div>
                            <div className="text-center text-900 text-xl mb-3">Product Name</div>
                            <div className="text-center text-900 font-medium text-xl mb-3">$50.00</div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};
