import React from 'react';
import avatar from '../../assets/logo.svg'

export const Testimonials = (props) => {
    return (
        <section id='testimonials'>

            <div className="surface-ground px-4 py-8 md:px-6 lg:px-8">
                <div className="text-orange-500 font-medium mb-3 text-center">Exclusive Community</div>
                <div className="text-2xl font-medium mb-5 text-center text-900">Join Our Developer Community</div>
                <div className="grid grid-nogutter">
                    <div className="col-12 lg:col-6 xl:col-4 p-4">
                        <div
                            className="surface-card shadow-2 p-4 flex flex-column align-items-center md:flex-row md:align-items-stretch h-full"
                        ><span><img
                            alt="" src={avatar} className="w-5rem"/></span>
                            <div
                                className="flex flex-column align-items-center md:align-items-stretch ml-4 mt-4 md:mt-0">
                                <p className="mt-0 mb-3 line-height-3 text-center md:text-left">No matter where you go,
                                    Blocks is the coolest, most happening thing around! We can't understand how we've
                                    been living without Blocks.</p><span className="text-900 font-medium mb-1 mt-auto">Jane Cooper</span>
                                <div className="text-600 text-sm">Belton, Inc</div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 lg:col-6 xl:col-4 p-4">
                        <div
                            className="surface-card shadow-2 p-4 flex flex-column align-items-center md:flex-row md:align-items-stretch h-full"
                        ><span><img
                            alt="" src={avatar} className="w-5rem"/></span>
                            <div
                                className="flex flex-column align-items-center md:align-items-stretch ml-4 mt-4 md:mt-0">
                                <p className="mt-0 mb-3 line-height-3 text-center md:text-left">I use Blocks often. We
                                    can't understand how we've been living without Blocks. Keep up the excellent
                                    work.</p>
                                <div className="text-900 font-medium mb-1 mt-auto">Jacob Jones</div>
                                <div className="text-600 text-sm">Vailan Corp</div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 lg:col-6 xl:col-4 p-4">
                        <div
                            className="surface-card shadow-2 p-4 flex flex-column align-items-center md:flex-row md:align-items-stretch h-full"
                        ><span><img
                            alt="" src={avatar} className="w-5rem"/></span>
                            <div
                                className="flex flex-column align-items-center md:align-items-stretch ml-4 mt-4 md:mt-0">
                                <p className="mt-0 mb-3 line-height-3 text-center md:text-left">Just what I was looking
                                    for. I would like to personally thank you for your outstanding product.</p>
                                <div className="text-900 font-medium mb-1 mt-auto">Leslie Alexander</div>
                                <div className="text-600 text-sm">Alfred, Ltd</div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 lg:col-6 xl:col-4 p-4">
                        <div
                            className="surface-card shadow-2 p-4 flex flex-column align-items-center md:flex-row md:align-items-stretch h-full"
                        ><span><img
                            alt="" src={avatar} className="w-5rem"/></span>
                            <div
                                className="flex flex-column align-items-center md:align-items-stretch ml-4 mt-4 md:mt-0">
                                <p className="mt-0 mb-3 line-height-3 text-center md:text-left">Really good. If you want
                                    real marketing that works and effective implementation - Blocks's got you
                                    covered.</p>
                                <div className="text-900 font-medium mb-1 mt-auto">Kristin Watson</div>
                                <div className="text-600 text-sm">Charot</div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 lg:col-6 xl:col-4 p-4">
                        <div
                            className="surface-card shadow-2 p-4 flex flex-column align-items-center md:flex-row md:align-items-stretch h-full"
                        ><span><img
                            alt="" src={avatar} className="w-5rem"/></span>
                            <div
                                className="flex flex-column align-items-center md:align-items-stretch ml-4 mt-4 md:mt-0">
                                <p className="mt-0 mb-3 line-height-3 text-center md:text-left">I will recommend you to
                                    my colleagues. Thanks guys, keep up the good work! Blocks is awesome! It's really
                                    wonderful.</p>
                                <div className="text-900 font-medium mb-1 mt-auto">Cody Fisher</div>
                                <div className="text-600 text-sm">Franki</div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 lg:col-6 xl:col-4 p-4">
                        <div
                            className="surface-card shadow-2 p-4 flex flex-column align-items-center md:flex-row md:align-items-stretch h-full"
                        ><span><img
                            alt="" src={avatar} className="w-5rem"/></span>
                            <div
                                className="flex flex-column align-items-center md:align-items-stretch ml-4 mt-4 md:mt-0">
                                <p className="mt-0 mb-3 line-height-3 text-center md:text-left">We can't understand how
                                    we've been living without Blocks. Blocks impressed me on multiple levels. It's
                                    really wonderful.</p>
                                <div className="text-900 font-medium mb-1 mt-auto">Floyd Miles</div>
                                <div className="text-600 text-sm">Hyper, Inc</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}