import React from 'react';

import {Header} from './components/Header'
import {Hero} from './components/sections/Hero'
import {About} from './components/sections/About'
import {Features} from './components/sections/Features'
import {AdvantagesForSchools} from './components/sections/AdvantagesForSchools'
import {AdvantagesForStudents} from './components/sections/AdvantagesForStudents'
import {Testimonials} from './components/sections/Testimonials'
import {Stats} from './components/sections/Stats'
import {Timeline} from './components/sections/Timeline'
import {Contact} from './components/sections/Contact'
import {Footer} from './components/Footer'
import JsonData from '../src/data/data.json'
import {StudentChoise} from "./components/sections/StudentChoise";


export const App = () => {
    return (
        <div>
            <Header data={JsonData.MenuItems}/>
            <Hero/>
            <About data={JsonData.About}/>
            <StudentChoise data={JsonData.StudentChoise}/>
            <Features data={JsonData.Features}/>
            <AdvantagesForSchools/>
            <AdvantagesForStudents/>
            <Testimonials data={JsonData.Testimonials}/>
            <Stats data={JsonData.Stats}/>
            <Timeline/>
            <Contact data={JsonData.Contact}/>
            <Footer/>
        </div>
    );
}

