import React from 'react';
import { Menubar } from 'primereact/menubar';
import { InputText } from 'primereact/inputtext';
import JsonData from '../data/data.json';

export const Header = (props) => {

    const start = <img alt="logo" src="showcase/images/logo.png" onError={(e) => e.target.src='https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png'} height="40" className="mr-2"></img>;
    const end = <InputText placeholder="Search" type="text" />;
    return (
        <header >
            <div className="card">
                <Menubar model={JsonData.MenuItems} start={start} end={end} />
            </div>
        </header>
    );
}
