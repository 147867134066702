import React from 'react';
import rally1 from '../../assets/images/rally/1.jpg';
import rally2 from '../../assets/images/rally/2.jpeg';
import rally3 from '../../assets/images/rally/3.jpg';
import rally4 from '../../assets/images/rally/4.jpg';


export const AdvantagesForSchools = (props) => {
    return (
        <section id="advantages-for-schools">
            <div className="surface-ground px-4 py-8 md:px-6 lg:px-8">
                <div className="text-5xl font-medium text-900 text-center mb-6">Best schools</div>
                <div className="grid -mt-3 -ml-3 -mr-3">
                    <div className="flex col-12 md:col-6 lg:col-3 mb-5 md:mb-0">
                        <div className="p-2 flex flex-column justify-content-between">
                            <div className="relative mb-2"><img
                                src={rally1} alt="image1"
                                className="w-full"/></div>
                            <div
                                className="absolute h-4rem w-4rem border-circle border-2 border-yellow-500 bg-gray-900 text-white inline-flex justify-content-center align-items-center"
                            >$42
                            </div>
                            <div className="text-900 font-medium text-xl mb-3 text-center">Product Name</div>
                            <div className="flex align-items-center justify-content-center mb-3"><i
                                className="pi pi-star-fill text-yellow-500 mr-2 text-2xl"></i>
                                <div className="text-900 font-medium text-xl line-height-1">4.8</div>
                            </div>
                            <p className="text-500 mt-0 mb-3 text-center line-height-3">Congue quisque egestas diam in.
                                Nunc sed blandit libero volutpat.</p>
                            <button type="button"
                                    className="p-ripple p-component cursor-pointer p-3 surface-900 hover:surface-800 border-none text-0 w-full transition-all transition-duration-300 text-center">View details<span role="presentation" className="p-ink"></span></button>
                        </div>
                    </div>
                    <div className="flex col-12 md:col-6 lg:col-3 mb-5 md:mb-0">
                        <div className="p-2 flex flex-column justify-content-between">
                            <div className="relative mb-2"><img
                                src={rally2} alt="image2"
                                className="w-full"/>
                            </div>
                            <div
                                className="absolute h-4rem w-4rem border-circle border-2 border-yellow-500 bg-gray-900 text-white inline-flex justify-content-center align-items-center"
                            >$42
                            </div>
                            <div className="text-900 font-medium text-xl mb-3 text-center">Product Name</div>
                            <div className="flex align-items-center justify-content-center mb-3"><i
                                className="pi pi-star-fill text-yellow-500 mr-2 text-2xl"></i>
                                <div className="text-900 font-medium text-xl line-height-1">3.9</div>
                            </div>
                            <p className="text-500 mt-0 mb-3 text-center line-height-3">Congue quisque egestas diam in.
                                Nunc sed blandit libero volutpat.</p>
                            <button type="button"
                                    className="p-ripple p-component cursor-pointer p-3 surface-900 hover:surface-800 border-none text-0 w-full transition-all transition-duration-300 text-center">View details<span role="presentation" className="p-ink"></span></button>
                        </div>
                    </div>
                    <div className="flex col-12 md:col-6 lg:col-3 mb-5 md:mb-0">
                        <div className="p-2 flex flex-column justify-content-between">
                            <div className="relative mb-2"><img
                                src={rally3} alt="image3"
                                className="w-full"/></div>
                            <div
                                className="absolute h-4rem w-4rem border-circle border-2 border-yellow-500 bg-gray-900 text-white inline-flex justify-content-center align-items-center"
                            >$42
                            </div>
                            <div className="text-900 font-medium text-xl mb-3 text-center">Product Name</div>
                            <div className="flex align-items-center justify-content-center mb-3"><i
                                className="pi pi-star-fill text-yellow-500 mr-2 text-2xl"></i>
                                <div className="text-900 font-medium text-xl line-height-1">4.5</div>
                            </div>
                            <p className="text-500 mt-0 mb-3 text-center line-height-3">Congue quisque egestas diam in.
                                Nunc sed blandit libero volutpat.</p>
                            <button type="button"
                                    className="p-ripple p-component cursor-pointer p-3 surface-900 hover:surface-800 border-none text-0 w-full transition-all transition-duration-300 text-center">View details<span role="presentation" className="p-ink"></span></button>
                        </div>
                    </div>
                    <div className="flex col-12 md:col-6 lg:col-3 mb-5 md:mb-0">
                        <div className="p-2 flex flex-column justify-content-between">
                            <div className="relative mb-2"><img
                                src={rally4} alt="image4"
                                className="w-full"/></div>
                            <div
                                className="absolute h-4rem w-4rem border-circle border-2 border-yellow-500 bg-gray-900 text-white inline-flex justify-content-center align-items-center"
                            >$42
                            </div>
                            <div className="text-900 font-medium text-xl mb-3 text-center">Product Name</div>
                            <div className="flex align-items-center justify-content-center mb-3"><i
                                className="pi pi-star-fill text-yellow-500 mr-2 text-2xl"></i>
                                <div className="text-900 font-medium text-xl line-height-1">5.0</div>
                            </div>
                            <p className="text-500 mt-0 mb-3 text-center line-height-3">Congue quisque egestas diam in.
                                Nunc sed blandit libero volutpat.</p>
                            <button type="button"
                                    className="p-ripple p-component cursor-pointer p-3 surface-900 hover:surface-800 border-none text-0 w-full transition-all transition-duration-300 text-center">View details<span role="presentation" className="p-ink"></span></button>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};